import styled from 'styled-components';

export const TreeviewContainerStyled = styled.div`

    border: solid 1px #DFDFDF;
    background-color: white;
    border-radius: 10px;
    height: calc(100% - 100px);
    overflow-y: auto;
    margin: 0 10px;
    padding-top: 10px;

    .item {
        padding: 15px 30px;
        text-align: left !important;
    }

    .submenuGroup {
        height: 0;
        opacity: 0;
        transition: height 250ms ease-in, opacity 100ms ease-in;
    }

    .submenuGroup.active .item {
        padding-left: 25px;
    }

    .submenuGroup.active {
        opacity: 1;
        height: auto;
    }

    .e-treeview .e-ul {
        padding: 0px!important;
    }

    .e-treeview .e-level-1>.e-text-content .e-list-text {
        font-family: Arial, Helvetica, sans-serif;
    }

    .e-treeview .e-level-2>.e-text-content .e-list-text {
        font-family: Arial, Helvetica, sans-serif;
    }

    .e-treeview .e-level-3>.e-text-content .e-list-text {
        font-family: Arial, Helvetica, sans-serif;
    }

    .e-treeview .e-list-text,
    .e-treeview .e-icon-expandable,
    .e-treeview .e-icon-collapsible {
        /* color: var(--primaryContrast) !important; */
    }

    .e-treeview .e-list-item {
        padding-left: 10px;
    }

    .e-treeview .e-list-item.e-has-child {
        padding-left: 15px;
    }

    .e-treeview .e-list-item {
    
      &>.e-fullrow {
        border: none !important;
        margin-right: 0px;
      }
      &.e-level-1>.e-fullrow {
        margin-left: 15px;
        width: 240px !important;
      }

      &.e-level-2>.e-fullrow {
        margin-left: 30px;
        width: 225px !important;
      }
      
      &.e-level-3>.e-fullrow {
        margin-left: 45px;
        width: 210px !important;
      }
    }


    .e-text-content .e-list-icon.e-icons.label-icon {
        margin-left: -27px !important;
        border: none !important;
        /* color: var(--primaryContrast) !important; */
    }

    .e-text-content.e-icon-wrapper .e-icons.e-icon-collapsible,
    .e-text-content.e-icon-wrapper .e-icons.e-icon-expandable {
        /* color: var(--primaryContrast) !important; */
    }

    .e-treeview .e-active>.e-fullrow,
    .e-treeview .e-hover>.e-fullrow {
        /* background-color: var(--primaryOverlay) !important; */
        border-radius: 10px !important;
        width: 230px !important;

    }

    .e-list-item.e-active:not(.e-has-child) .e-list-text {
       color: var(--secondaryColor) !important;
    }

    .e-list-item .e-list-text {
       color: var(--basic) !important;
    }
`;