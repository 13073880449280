import store from 'src/store/store';
import { run as runActions } from '../../../../store/workspaces/workspaces/run';

export  const actionComplete = async (args, kanbanData, navData, filter ) => {

  const heartbeatUpdateData = store.getState().heartbeatUpdateData;
  if(heartbeatUpdateData.data !== null) return;

  if (args.requestType === 'cardChanged') {

    const onMoveKey = navData.widgetData.events.find(event => event.type === 'onMove')?.key;
    if (!onMoveKey) return args.cancel = true;

    args.changedRecords.map(async (record) => {
      const params = {
        ...record,
        sequence: args.index
      };

      await runActions(onMoveKey, navData.renderID, params, null);

      if(filter.length) return;
    });
  }
};