import styled from 'styled-components';

interface WorkspaceInputProps {
  editing: string;
  inputlength: any;
}

interface WorkspaceButtonProps {
  active: any;
}

interface WorkspaceContainerProps {
  active: any;
}

interface DynamicRowProps {
  rowheight: string;
}


export const WorkspaceInputStyled = styled.input<WorkspaceInputProps>`
    border: none;
    border-bottom: 2px solid rgb(181, 181, 181); 
    outline: none !important;
    width: ${(props) => (props.editing === 'true' ? `${(props.inputlength + 4) * 7}px` : null)};
`;


export const WorkspaceButtonBarStyled = styled.div`
    display: flex;
    margin-top: 2px;
    margin-left: 1px;
    display: 'flex' !important;
    justify-content: 'space-between' !important; 
    align-items: 'center' !important; 
    width: '100%';
`;

export const WorkspaceButtonContainerStyled = styled.div<WorkspaceButtonProps>`
    background-color: ${({ active }) => (active ? 'white' : '#F5F5F5')};
    border: solid 1px #DFDFDF;
    border-bottom: ${({ active }) => (active ? 'none' : 'solid 1px #DFDFDF')};
    margin: 5px 0 -1px -1px;
    padding: 2px;
    border-radius: 5px 5px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default !important;

    &:hover {
      background-color: ${({ active }) => (active ? 'white' : '#DFDFDF')};
    }

    .workspace-button {
      color: var(--basic) !important;
      background-color: transparent;
      border: none;
      cursor: pointer;
      font-size: 1rem;
    }

    .workspace-button.e-icons::before {
      color: rgb(225, 220, 220)!important;
      cursor: pointer;
    }
    
    .e-drag-and-drop,
    &:hover .e-drag-and-drop.disabled,
    &[active="1"] .e-drag-and-drop.disabled,
    &:hover .workspace-button.e-icons:disabled::before,
    &[active="1"] .workspace-button.e-icons:disabled::before {
        color: rgb(225, 220, 220)!important;
        cursor: default !important;
    }

    &:hover .e-drag-and-drop,
    &[active="1"] .e-drag-and-drop:not(.disabled),
    &:hover .workspace-button.e-icons::before,
    &[active="1"] .workspace-button.e-icons:not(disabled)::before {
        color: rgb(100, 100, 100)!important;
    }

    .e-drag-and-drop {
        padding: 4px;
        font-size: 12px;
        cursor: grab !important;
    }
    
    .workspace-button.e-icons {
        padding: 4px;
        font-size: 10px;

        &::before {
          margin: 0 !important;
        }
    }
`;

export const WorkspaceContentContainerStyled = styled.div<WorkspaceContainerProps>`
    height: calc(100vh - 44px);
    width: calc(100% - 12px);
    display: ${({ active }) => (active ? null : 'none')};

    .e-btn {
      background-color: #c0c0c0 !important;
    }
    
    .e-control.e-btn {
      background-color: white !important;
      border: 1px solid #D3D3D3 !important;
      border-radius: 12px;
      box-shadow: none !important;
      color: var(--basic) !important;
    }

    .e-control.e-btn:hover,
    .e-control.e-btn:active {
      background-color: #F5F5F5 !important;
    }

    .code-cell .e-control.e-btn,
    .right.item .e-control.e-btn {
      padding: 3px 0px 0px 0px !important;
      border-radius: 4px;
      margin: 0px 3px 0px 3px;
      height: 21px;
      width: 21px;
    }

    .code-cell .e-control.e-btn .e-icons,
    .right.item .e-control.e-btn .e-icons {
      font-size: 13px !important;
    }

    .code-cell .e-control.e-btn.e-disabled,
    .code-cell .e-control.e-btn.e-disabled *,
    .right.item .e-control.e-btn.e-disabled,
    .right.item .e-control.e-btn.e-disabled * {
      cursor: default !important;
    }

`;

export const InnerColumnStyled = styled.div`
    height: 100%;
`;

export const DynamicRowStyled = styled.div<DynamicRowProps>`
    margin: 2px;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    height:  ${props => props.rowheight};

    & Form {
      height: 100%;
    }
`;


