/* eslint-disable no-case-declarations */
/* eslint-disable react/jsx-no-undef */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import MenuBar from '../../../../components/MenuBar/MenuBar';
import { ColumnsDirective, GridComponent, Inject, ContextMenu, Filter, Page, ExcelExport, Selection, Resize, Toolbar, CommandColumn, InfiniteScroll, Sort, Aggregate } from '@syncfusion/ej2-react-grids';
import { WidgetProvider } from '../../WidgetContext';
import { setCustomContextMenu } from '../services/setContextMenu';
import { cellSelecting } from './services/cellSelecting';
import { contextMenuClick } from '../services/contextMenuClick';
import { WidgetContainerStyled, WidgetContentStyled, WidgetLabelStyled } from '../../styles';
import { GridContainerStyled } from '../styles';
import setClassNames from '../services/setClassNames';
import { useFetchdata } from './hooks/useFetchData';
import { useEventListener } from './hooks/useEventListener';
import { GridViewContext } from './context/Grid.view.context';
import { useSetCurrentWorkspace } from './hooks/useSetCurrentWorkspace';
import { created } from './services/created';
import { actionComplete } from './services/actionComplete';
import { renderColumn } from './services/renderColumns';

const GridWidgetView = () => {
  const {
    navData,
    actionsState,
    gridViewInstance,
    currentWorkspaceUuid,
    gridEditData,
    globalKeyEvent,
    dateFormat,
    timeFormat,
    t,
    settings,
    aggregations
  } = useContext(GridViewContext);

  useFetchdata();
  useEventListener();
  useSetCurrentWorkspace();


  if (!navData?.widgetData || !gridEditData || !timeFormat || !dateFormat) {
    return null;
  }


  return (
    <WidgetContainerStyled>
      <WidgetProvider value={{
        instance: gridViewInstance.current,
        widgetData: navData.widgetData,
        actionsState,
        gridEditData,
        currentWorkspaceUuid
      }}>
        {navData.widgetData.label && (
          <WidgetLabelStyled>{t(navData.widgetData.label)}</WidgetLabelStyled>
        )}
        {navData.widgetData.menu && (
          <MenuBar
            key={`menu-${navData.widgetData.menu.id}`}
            menu={navData.widgetData.menu}
            widgetTools={null}
          />
        )}
        <WidgetContentStyled>
          <GridContainerStyled css={navData.widgetData.style} height={navData.widgetData.height}>
            <GridComponent
              locale={JSON.parse(localStorage.getItem('language'))}
              ref={gridViewInstance}
              id={`grid-${navData.widgetData.key}`}
              key={`grid-${navData.widgetData.key}`}
              height={navData.widgetData.height || '100%'}
              rowHeight={28}
              dataSource={gridEditData}
              allowExcelExport
              allowFiltering
              allowResizing
              allowSelection
              allowSorting
              allowPaging={navData.widgetData.allowPaging}
              enableInfiniteScrolling={!navData?.widgetData?.allowPaging}
              selectionSettings={settings.selectionSettings ?? {}}
              filterSettings={settings.filterSettingsMenu}
              contextMenuItems={setCustomContextMenu(navData)}
              pageSettings={navData.widgetData.allowPaging ? settings.pageSettings : settings.pageSettingsInfinite}
              cellSelecting={(args) => cellSelecting(args, navData, globalKeyEvent, actionsState)}
              contextMenuClick={(args) => contextMenuClick(args, gridViewInstance.current, navData ? setCustomContextMenu(navData) : null)}
              actionComplete={(args) => actionComplete(args, navData, actionsState)}
              created={() => created(gridViewInstance, navData)}
              aggregates={aggregations}
              rowDataBound={(args) => setClassNames(args)}
            >
              <ColumnsDirective>
                {navData.widgetData.columns.map(renderColumn)}
              </ColumnsDirective>
              <Inject services={[ContextMenu, Filter, Page, ExcelExport, Selection, Resize, Toolbar, CommandColumn, InfiniteScroll, Sort, Aggregate]} />
            </GridComponent>
          </GridContainerStyled>
        </WidgetContentStyled>
      </WidgetProvider>
    </WidgetContainerStyled>
  );
};

GridWidgetView.propTypes = {
  navData: PropTypes.object,
  actionsState: PropTypes.object
};

export default GridWidgetView;
